<template>
    <div class="instructions-for-use">
        <div v-if="isSupplier" class="supplier-box">
            <div class="image-box">
                <img src="../../../assets/supplier-sysm.jpg" alt="">
            </div>
        </div>
        <div v-else class="customer-box">
            <img src="../../../assets/customer-sysm.jpg" alt="">
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    name: 'InstructionsForUse',
    data() {
        return {
            userInfo: null,
            isSupplier: false,
        }
    },
    computed: {
        ...mapState("homeModule", [
            "userInfos", // 完整用户信息
        ]),
    },
    created() {
        if (!this.userInfos) {
            this.getUserInfo().then(res => {
                let {
                    code,
                    data
                } = res.data;
                if (code === '0') {
                    this.userInfo = data;
                    this.setUserInfos(data);
                    this.isSupplier = this.$store.state.userInfo.userType === '0'
                }
            })
        } else {
            this.userInfo = this.userInfos
        }
        // 0表示是供应商   等于1表示是客户
        this.isSupplier = this.userInfo.userType === '0'
    },
    methods: {
        ...mapActions("systemModule", [
            "getUserInfo", //请求用户信息的接口
        ]),
        ...mapMutations('homeModule',[
            'setUserInfos',
        ])
    }
}
</script>

<style lang="less" scoped>
.instructions-for-use {

}
</style>
